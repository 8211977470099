<script setup lang="ts">
import ProductList from '~/components/plp/ProductList.vue';
import NewFilterBar from '~/components/filter-bar-v2/index.vue';
import FilterBar from '~/components/filter-bar/index.vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';
import { fetchBrandProducts, useProductsList } from '~/data/products';
import { useSeoData } from '~/data/entities';
import { useDebounceFn } from '@vueuse/core';
import PlpHeader from '~/components/plp/Header.vue';
import Description from '~/components/plp/Description.vue';
import { useNodesQuery } from '~/data/nodes';
import { Separator } from 'radix-vue';

import NodesBar from '~/components/plp/NodesBar.vue';
import type { PlpUiExp } from '~/types/statsig.ts';
const { $cathodeClient } = useNuxtApp();
const { $eventClient } = useNuxtApp();

const route = useRoute();
const newFilterUi = inject('newFilterUi') as Ref<boolean>;

const handle = ref(route.params.handle as string);
const filters = ref<string>(route.fullPath.split('?')[1] || '');

const { storeType } = useGlobalStore();

const enabled = ref(true);

const { data: seo, suspense, error } = useSeoData(handle, enabled);
const disableSignReload = ref(false);
const toggleDisableSignReload = () => {
	disableSignReload.value = !disableSignReload.value;
};

provide('toggleDisableSignReload', toggleDisableSignReload);

watch(disableSignReload, (newValue) => {
	if (newValue) {
		enabled.value = false;
	} else {
		enabled.value = true;
	}
});

watch(seo, (seoData) => {
	if (!seoData) return;
	if (Object.keys(seoData || {}).length) {
		$eventClient.sendEvent('view-item-list', {
			id: seoData.id,
			title: seoData.title,
			productCount: seoData.subtitle,
		});
	}
});

onServerPrefetch(async () => {
	await suspense();
});

await suspense();
const {
	data,
	isLoading,
	hasNextPage,
	fetchNextPage,
	isFetchingNextPage,
	error: productListError,
} = useProductsList({
	queryKey: ['brands', handle, filters],
	queryFn: ({ pageParam: cursor }) => {
		return fetchBrandProducts(handle.value, cursor as string, filters.value);
	},
	enabledValue: enabled,
});

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	filters.value = route.fullPath.split('?')[1] || '';
	handle.value = route.params.handle as string;
}, 200);

const isNodesQueryEnabled = ref(false);

const { data: nodesData } = useNodesQuery(
	'brands',
	handle,
	isNodesQueryEnabled,
);

watch(route, (route) => {
	if (!route.name!.toString().includes('brands')) {
		return;
	}
	debounce(route);
});

const plpNewUi = inject('plpNewUi') as Ref<PlpUiExp>;

onMounted(() => {
	if (!seo.value || error.value) {
		console.log('error', error.value, seo.value);
		throw createError({
			statusCode: 404,
			message: 'Page not found',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
	isNodesQueryEnabled.value = plpNewUi.value.allowed;
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		page: 'PLP',
		statusCode: 200,
		referrer: document.referrer,
		collection_handle: route.params.handle as string,
	});
});

const showNodesBar = computed(() => {
	if (plpNewUi.value.allowed && plpNewUi.value.showOnBrands) return true;
	return false;
});

const nodesBarData = computed(() => {
	if (nodesData.value && nodesData.value?.length && showNodesBar.value) {
		if (plpNewUi.value.l4NodesOnly)
			return nodesData.value.filter((node) => node.node_level === 4);

		return nodesData.value;
	}

	return [];
});

watch(isLoading, (isLoading) => {
	if (!isLoading && productListError.value) {
		throw createError({
			statusCode: 404,
			message: 'Failed to load products',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
});

useSeoMeta({
	ogTitle: seo.value?.title ?? null,
	title: seo.value?.title ?? null,
	description: seo.value?.description ?? '',
});
</script>
<template>
	<div>
		<PlpHeader :data="seo" />

		<ClientOnly>
			<div
				v-if="showNodesBar && nodesBarData && nodesBarData.length > 0"
				class="flex flex-col gap-3xl border-b border-gray-200 py-3xl"
			>
				<!-- TODO: remove this if not used -->
				<!-- <SeoHeader :seo="seo" :showing-banner="visible && !!shippingConfig" /> -->
				<NodesBar v-if="nodesBarData.length" :feed-nodes="nodesBarData" />
			</div>
			<NewFilterBar v-if="newFilterUi" :handle="handle" />
			<FilterBar v-else :handle="handle" />

			<ProductList
				:infinite-loader="true"
				:fetch-next-page="fetchNextPage"
				:is-loading="isLoading"
				:data="data"
				:has-next-page="hasNextPage"
				:is-fetching-next-page="isFetchingNextPage"
			/>
			<template #fallback>
				<FilterbarSkeleton />
				<ProductListSkeleton />
			</template>
		</ClientOnly>

		<div
			v-if="!!seo?.description && storeType === STORE_TYPE.MARKETPLACE"
			class="w-full"
		>
			<Separator class="seo-description-separator" />
			<Description :description="seo.description" :heading="seo.title" />
		</div>
	</div>
</template>

<style scoped>
.seo-description-separator {
	@apply !bg-gray-100 h-xl mt-5xl;
}
</style>
